import { useEffect } from "react";
import { GetAPIResult, useGetFromAPI } from "./useGetFromAPI";

// REACT_APP_GIT_HASH es seteja des del script 'githash_build'.
// Si estem fent start normal, el tenim corrent en desenvolupament, deixem la versió com a 'dev'. si ens peta, sabem com fer clean del localStorage.
const VERSION = process.env.REACT_APP_GIT_HASH ?? "dev";

/**
 * Crida la API per agafar les dades, pero se les guarda al localStorage.
 * D'aquesta forma, la propera vegada que es torni a cridar, primer l'agafa del localStorage.
 *
 * No utilitzar-ho per queries que el path canvia molt sovint, sino saturarem el espai del localStorage.
 */
export function useCachedAPI<T>(path: string | null): GetAPIResult<T> {
  const result = useGetFromAPI<T>(path);

  useEffect(() => {
    // Quan ens arribi un valor nou el guardem al localStorage
    if (result.value) {
      localStorage.setItem(
        result.loadedPath!,
        JSON.stringify({ version: VERSION, value: result.value })
      );
    }
  }, [result.value, result.loadedPath]);

  // Recuperem del localStorage el valor.
  const cacheContents = path ? JSON.parse(localStorage.getItem(path)!) : null;

  // Si la versió no coincideix, lo millor es no utilitzar els valors cachejats, perque poden tindre un format que faci que peti.
  const cachedValue: T | null =
    cacheContents?.version === VERSION ? cacheContents.value : null;

  // El que tornem serà el valor del local storage si el de debò encara no té el valor que toca.
  const value = result.loadedPath !== path ? cachedValue : result.value;

  return {
    value,
    loading: result.loading,
    loadedPath: value ? path : null,
  };
}
